.bio_img {
    width: 80%;
    max-width: 350px;
    overflow: hidden;
    margin: 0 auto;
    line-height: 0;

    img {
        max-width: 100%;
        max-height: 100%;
        mix-blend-mode: multiply;
    }
}

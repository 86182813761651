.form {
    margin: 0 auto;
    max-width: 850px;
    width: 100%;

    h3 {
        border-bottom: solid 1px $blue;
        display: inline;
        font-family: $walbaum;
    }

    form {
        margin: 0 auto;
        width: 90%;
    }

    &-group {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;

        > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 15px;

            label {
                padding-right: 25px;
                text-align: right;
                text-transform: uppercase;
                @include font-size(0.8rem);
            }

            input,
            textarea,
            > div { flex-grow: 1; }

            input,
            textarea {
                background-color: transparent;
                border: none;
            }

            input { border: 0; border-bottom: solid 1px $blue; }
            textarea { border: solid 1px $blue; }

            .cke_editable {
                color: $blue;
                font-family: $regular;
            }
        }

        &.biography,
        &.message {
            > div { align-items: flex-start; }
        }

        &.message {
            textarea { height: 180px; }

            > div {
                flex-direction: column;
                align-items: flex-start;

                label { margin-bottom: $margin-xs; }
                textarea { width: 100%; }
            }
        }

        &.imagefile {
            justify-content: flex-end;
            margin: 15px 15px 0 auto;

            > div { padding: 0; }

            label {
                background-color: $blue;
                color: $white;
                cursor: pointer;
                margin-right: 15px;
                min-width: initial;
                order: 2;
                padding: 15px;
            }

            input {
                border-bottom: none;
                width: 0.1px;
                order: 1;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                z-index: -1;
            }
        }

        &.select {
            > div {
                align-items: center;
            }

            select {
                background-color: $white;
                color: $blue;
                padding: 5px;
                text-transform: uppercase;
                @include font-size(0.8rem);
            }
        }
    }

    &-submit {
        display: flex;
        justify-content: center;
        padding: 15px;

        button {
            background-color: $blue;
            color: $white;
            border: solid 1px $blue;
            border-radius: 10px;
            cursor: pointer;
            padding: 6px 12px;
            text-transform: uppercase;
            transition: background-color 0.3s linear, color 0.3s linear;
            @include font-size(1rem);

            &:hover {
                background-color: $blue;
                color: $white;
            }
        }
    }

    &-message {
        background-color: $gold;
        border-radius: 15px;
        margin-left: auto;
        margin-right: auto;
        max-width: 500px;
        padding: 6px 12px;
        width: 60%;
        text-align: center;
        @include font-size(0.9rem);

        span {
            color: $white;
            line-height: 1.6em;
        }
    }

    &.contact {
        margin-top: $margin-m;

        label {
            @include font-size(1rem);
        }
    }

    &.login {
        height: 100%;

        h2 {
            border-bottom: solid 1px $blue;
            font-family: $walbaum;
            padding-bottom: 5px;
        }

        form {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 80%;
            justify-content: space-around;
        }
    }

    .captcha {
        > div {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;
        }

        input {
            border: 0;
            border-bottom: solid 1px $blue;
            background-color: transparent;
        }

        #ExampleCaptchaUserRegistration_CaptchaDiv {
            margin: 25px !important;
        }
    }
}

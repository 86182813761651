// RESPONSIVE FRONT-END FOR TABLET PORTRAIT (> 768px)
@media screen and (min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .row {
        &.gallery {
            flex-wrap: wrap;
            flex-direction: row;

            .col { width: 50%; }
        }

        .author {
            > a { height: 400px; }
        }
    }
}

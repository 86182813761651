.row {
    .gallery {
        &-filter {
            background-color: $blue;
            height: 100%;
            left: 0;
            mix-blend-mode: screen;
            position: absolute;
            top: 0;
            width: 100%;
        }

        &-link {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;

            > a {
                height: 450px;
                overflow:hidden;
                position: relative;
                transition: box-shadow 0.4s ease-in-out, background-size 0.4s ease-in-out;
                width: 100%;
                @include box-shadow(-2px, $blue, 35, 2px, 15px);

                > div {
                    background-size: cover;
                    background-position: center;
                    height: 100%;
                    transition: transform 0.3s ease-in-out;
                    width: 100%;
                }
            }

            &.admin {
                border-radius: 30px;
                cursor: auto;
                overflow: hidden;
                position: relative;

                .admin-button {
                    align-items: center;
                    background-color: $white;
                    border-bottom: solid 1px $blue;
                    border-radius: 30px 30px 0 0;
                    display: flex;
                    height: 50px;
                    justify-content: space-between;
                    position: absolute;
                    top: 0;
                    width: 100%;

                    * {
                        cursor: pointer;
                    }

                    > * {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        flex-grow: 1;
                        transition: box-shadow 0.3s ease-in-out;

                        &:hover {
                            box-shadow: inset -5px -5px 30px rgba($blue, 0.2);
                        }
                    }

                    > form {
                        border-left: solid 1px $blue;

                        &:hover {
                            box-shadow: inset 5px -5px 30px rgba($blue, 0.2);
                        }
                    }
                }

                > a {
                    box-shadow: none;
                }

                &:hover {
                    > a { box-shadow: none; }
                }
            }

            &:hover {
                > a {
                    > div { transform: scale(1.1); }
                    @include box-shadow(-2px, $blue, 55, 2px, 20px);
                }
            }
        }

        &-element {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: $margin-xxs;
            margin-bottom: $margin-xs;

            > h3 {
                margin-top: $margin-xs;

                &::after {
                    background-color: $blue;
                    content: "";
                    display: block;
                    text-align: center;
                    height: 1px;
                    margin: 15px auto 0 auto;
                    width: 25px;
                }
            }

            &.book {
                .gallery-link.admin {
                    height: 250px;
                    border-radius: 30px;

                    > a {
                        align-items: center;
                        background-color: $blue;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 50px 25px 0;
                        text-align: center;

                        > * { color: $white; }

                        > h3 {
                            font-family: $bold;
                            line-height: 1.6em;
                            text-transform: uppercase;

                            &::after {
                                content: "";
                                display: block;
                                background-color: $white;
                                margin: 15px auto 0;
                                width: 40px;
                                height: 1px;
                            }
                        }
                    }
                }
            }

            &.admin-new {
                align-self: center;
            }
        }

        &-bookshelf {
            display: none;

            > div {
                background-image: url('../../../public/images/icon/bookshelf.jpg');
                background-size: cover;
                background-position: center;
                width: 100%;
                height: 400px;
            }
        }

        &-admin-new {
            align-items: center;
            background-color: $blue;
            border-radius: 60px;
            display: flex;
            height: 60px;
            justify-content: center;
            transition: box-shadow 0.4s ease-in-out;
            width: 60px;

            &:hover { @include box-shadow(-2px, $blue, 35, 2px, 10px); }

            > span {
                color: $white;
                transform: translateY(-4px) translateX(1px);
                @include font-size(4.052rem);
            }
        }
    }
}

#gallery {
    position: relative;

    &-detail {
        background-color: $white;
        height: 100vh;
        overflow: scroll;
        padding: $margin-m $margin-xs $margin-s;
        position: fixed;
        top: 0;
        right: 0;
        transition: right 0.4s linear;
        width: 100vw;
        z-index: 102;

        &.closed { right: -100vw; }

        .gallery-infos {
            display: none;
            min-height: 100%;

            h4 {
                text-align: center;
            }

            hr {
                margin: $margin-xs 0;
                width: 100%;
            }

            &.visible {
                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }
        }

        p {
            text-align: center;
            @include font-size(0.9rem);
        }

        .author-books,
        .collaborator-link {
            align-self: flex-start;

            li {
                margin-bottom: 5px;
                text-align: left;

                &::before {
                    margin-right: 15px;
                    font-size: 0.6em;
                }
            }
        }

        .author-books { li::before { content: "✺"; } }
        .collaborator-link { li::before { content: "✺"; } }
    }

    &-detail-mask {
        background-color: rgb(9, 23, 65);
        cursor: pointer;
        display: none;
        height: 100vh;
        left: 0;
        opacity: 0.3;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 101;
    }

    &-close {
        padding: $margin-xxs;
        position: absolute;
        right: 0;
        top: 0;

        img {
            cursor: pointer;
            height: 20px;
        }
    }
}

.photo-credits {
    text-align: left;
    margin-top: $margin-xs;
    align-self: flex-start;

    @include font-size(0.8rem);

    &::before {
        content: "© ";
        color: $gold;
    }
}

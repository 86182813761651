.bookshelf {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
    width: 90%;

    &.large { max-width: 400px; }
    &.small { max-width: 200px; }

    &__top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;

        &.between { justify-content: space-between; }
        &.around { justify-content: space-around; }
        &.right { justify-content: flex-end; }

        h2 {
            margin: 0 25px;
            z-index: 2;
        }
    }

    &__bottom {
        height: $book-width + px;
        width: 100%;
        margin-top: $book-width + px;
    }

    &.manuscrits-responsive {
        align-self: flex-end;
        display: block;
    }

    &.manuscrits-desktop { display: none; }
}

.books {
    height: 100%;
    display: flex;
    align-items: flex-end;

    .book {
        width: $book-width + px;
        height: $book-height + px;
        margin: 0 ($book-margin + px) 0 0;

        &.small { height: $book-small + px; }

        &.fall.left { transform: rotate(-8deg) translateX(4px); }
        &.fall.right { transform: rotate(8deg) translateX(-4px); }
    }

    &.horizontal {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;

        .book {
            width: $book-height + px;
            height: $book-width + px;
            margin: ($book-margin + px) 0 0 0;

            &.small { width: $book-small + px; }
        }
    }
}

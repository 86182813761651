@font-face {
    font-family: 'Inria';
    src: url('../../../public/media/fonts/InriaSans-Light.ttf');
}

@font-face {
    font-family: 'Inria Bold';
    src: url('../../../public/media/fonts/InriaSans-Regular.ttf');
}

@font-face {
    font-family: 'Inria Italic';
    src: url('../../../public/media/fonts/InriaSans-LightItalic.ttf');
}

@font-face {
    font-family: 'Inria BoldItalic';
    src: url('../../../public/media/fonts/InriaSans-Italic.ttf');
}

@font-face {
    font-family: 'Walbaum';
    src: url('../../../public/media/fonts/Walbaum.ttf');
}
